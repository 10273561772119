import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import PropTypes from 'prop-types'

// Components
import PostLayout from '../components/Layout/PostLayout'
import PreviewImage from '../components/Blog/PreviewImage'
import YouTube from '../components/Blog/YouTube'

const SinglePost = ({ location, data }) => {
  const { title, featureImg, youTubeId } = data?.mdx?.frontmatter
  const body = data?.mdx?.body
  const ytLink = youTubeId || null

  return (
    <PostLayout location={location} seo={data.mdx.frontmatter}>
      {featureImg !== null && (
        <div className="mb-5">
          <PreviewImage title={title} img={featureImg} />
        </div>
      )}
      <div className="blog-post-content">
        <MDXRenderer>{body}</MDXRenderer>
        <YouTube link={ytLink} title={title} />
      </div>
    </PostLayout>
  )
}

SinglePost.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default SinglePost

export const singlePostQuery = graphql`
  query singlePostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        slug
        youTubeId
        trackBrand
        featureImg {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
