import React from 'react'
import PropTypes from 'prop-types'

// React Icons
import { BsYoutube } from 'react-icons/bs'

const YouTube = ({ link, title }) => {
  if (link !== null && link.toLowerCase().trim() === 'no') return ``

  return (
    <>
      <h3>YouTube</h3>
      <div className="ratio ratio-16x9">
        {/^embed/.test(link) ? (
          <iframe
            src={`https://www.youtube.com/${link}`}
            title={title}
            className="ratio ratio-16x9"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <BsYoutube size={75} style={{ color: 'var(--red-1)' }} />
            <h3 className="m-1">Coming Soon</h3>
          </div>
        )}
      </div>
    </>
  )
}

YouTube.defaultProps = {
  link: null,
}

YouTube.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default YouTube
