import React from 'react'
import PropTypes from 'prop-types'

// Components
import Seo from '../Seo'
import Header from '../Header'
import Footer from '../Footer'
import PageTitle from './PageTitle'

const PostLayout = ({ location, seo, children, is404 }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location?.pathname === rootPath
  const { title, slug, featureImg, keywords } = seo

  return (
    <>
      <Seo
        title={title}
        slug={slug}
        img={
          featureImg !== null ? featureImg.childImageSharp.gatsbyImageData.images.fallback.src : ''
        }
        keywords={keywords}
      />
      <div data-is-root-path={isRootPath} className="d-flex flex-column min-vh-100">
        <Header />
        <main>
          <PageTitle
            title={title}
            is404={is404}
            breadArray={[
              { name: 'blog', href: `blog` },
              { name: title, href: slug },
            ]}
          />
          <section className="container">
            <div className="col-10 offset-1 col-lg-8 offset-lg-2 mb-5">{children}</div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}

PostLayout.defaultProps = {
  is404: false,
}

PostLayout.propTypes = {
  location: PropTypes.object.isRequired,
  seo: PropTypes.object.isRequired,
  is404: PropTypes.bool,
}

export default PostLayout
